import { PhoneNumberUtil } from "google-libphonenumber";
import { handleError } from "./errorHandler";
import { EMAIL_REG_EXP, MX_PHONE, NAME_REG_EXP } from './consts';
import { emailValidation, phoneValidation } from './validation';
import { selectValidMessagesPassword, isPasswordValid } from "./validate_password";
import { validateBirthday } from "./validate_birthday";
import { trackGtagEvent } from "./utils.js";

const signUpForm = document.getElementById("signup-form");
const phoneNumberInstance = PhoneNumberUtil.getInstance();

const firstStepContainer = document.querySelector(".first-step-section");
const firstStepInputsArr = [...firstStepContainer.querySelectorAll('.sign-up__input')];
const secondStepContainer = document.querySelector(".second-step-section");
const secondStepInputsArr = [...secondStepContainer.querySelectorAll('.sign-up__input')];

const nameErrorContainer = signUpForm.querySelector(".error__message.signup-first-name");
const surnameErrorContainer = signUpForm.querySelector(".error__message.signup-last-name");
const couponErrorContainer = signUpForm.querySelector(".error__message.signup-coupon-code");
const phoneErrorContainer = signUpForm.querySelector(".error__message.signup-phone");
const hintPasswordElements = signUpForm.querySelectorAll('.signup-hint-password');
const emailErrorContainer = signUpForm.querySelector(".error__message.signup-email");

const addErrors = (errorContainer) => {
	errorContainer?.classList.add('show');
}

const getEmailError = (email) => {
	if (email.length < 7) {
		return 'Mínimo 7 caracteres';
	}
	if (email.length > 50) {
		return 'Máximo 50 caracteres';
	}
	if (!email.match(EMAIL_REG_EXP)) {
		return 'Tu correo electrónico es incorrecto';
	}
	return '';
};

const validatePhoneNumber = (phoneNumber) => {
	if (phoneNumber.length >= 6) {
		try {
			const phoneNumberWithCode = phoneNumberInstance
				.parseAndKeepRawInput(`+52${phoneNumber}`, '+52');

			if (
				!phoneNumber.match(MX_PHONE) ||
				!phoneNumberInstance.isValidNumber(phoneNumberWithCode)
			) {
				return 'El número de teléfono es incorrecto';
			}
		} catch (e) {
			return 'El número de teléfono es incorrecto';
		}
	} else {
		return 'El número de teléfono es incorrecto';
	}
	return '';
}

const getTextFieldError = (value) => {
	if (value.length < 2) {
		return 'Mínimo 2 caracteres';
	}
	if (value.length > 50) {
		return 'Máximo 50 caracteres';
	}
	if (!value.match(NAME_REG_EXP)) {
		return 'Por favor, introduzca sólo el texto en latín o en español';
	}
	return '';
};

const getCouponError = (value) => {
	if (value.length > 20) {
		return 'Máximo 20 caracteres'
	}
	return '';
};

const validateTextField = (value, errorContainer) => {
	handleError(getTextFieldError(value), errorContainer);
}

const validateFirstStepInput = (inputElem) => {
	const startInputTime = sessionStorage.getItem("startInputTime"); // In every session we will have new time
	if (!startInputTime) {
		sessionStorage.setItem("startInputTime", Date.now().toString());
	}

	const attr = inputElem.getAttribute('data-validation');

	if (attr === 'email') {
		handleError(getEmailError(inputElem.value), emailErrorContainer);
	}

	if (attr === 'password') {
		selectValidMessagesPassword(inputElem.value, hintPasswordElements);
	}

	if (attr === 'phone') {
		handleError(validatePhoneNumber(inputElem.value), phoneErrorContainer);
	}
}

const validateSecondStepInput = (inputElem) => {
	const attr = inputElem.getAttribute('data-validation');

	if (attr === 'name') {
		validateTextField(inputElem.value, nameErrorContainer);
	}

	if (attr === 'surname') {
		validateTextField(inputElem.value, surnameErrorContainer);
	}

	if (attr === 'couponCode') {
		handleError(getCouponError(inputElem.value), couponErrorContainer);
	}
}

const validateAllFirstStepInputs = () => {
	firstStepInputsArr.forEach((input) => {
		validateFirstStepInput(input);
		input.addEventListener('input', (e) => validateFirstStepInput(e.target));
	});
}

const validateAllSecondStepInputs = () => {
	secondStepInputsArr.forEach((input) => {
		validateSecondStepInput(input);
		input.addEventListener('input', (e) => validateSecondStepInput(e.target));
	});
}

const validateFirstStep = () => {
	const emailInput = document.getElementById('signup-email');
	const phoneInput = document.getElementById('signup-phoneNumber');
	const passwordInput = document.getElementById('signup-password');

	addErrors(emailErrorContainer);
	addErrors(phoneErrorContainer);

	let prevEmailValid = null;
	let prevPhoneNumberValid = null;
	let prevPasswordValid = null;

	firstStepInputsArr.forEach((input) => {
		input.addEventListener('input', (e) => {
			const startInputTime = sessionStorage.getItem("startInputTime"); // In every session we will have new time

			if (!startInputTime) {
				sessionStorage.setItem("startInputTime", Date.now().toString());
			}

			validateFirstStepInput(e.target);
		});
	});

	const checkEmail = async (field) => {
		const { value } = field.target;
		let isError;

		if (emailErrorContainer.innerHTML === '' && value.length > 0) {
			isError = await emailValidation(value.trim(), emailErrorContainer);
		}

		const isValidEmail = !getEmailError(value) && !isError;

		if (isValidEmail !== prevEmailValid) {
			if (isValidEmail) {
				trackGtagEvent('sign_up_form_email_validated');
			}
			else {
				trackGtagEvent('sign_up_form_email_invalid');
			}
		}

		prevEmailValid = isValidEmail;
	};

	const checkPhone = async (field) => {
		const { value } = field.target;
		let isError;

		if (phoneErrorContainer.innerHTML === '' && value.length > 0) {
			isError = await phoneValidation(value, phoneErrorContainer);
		}

		const isValidPhone = !validatePhoneNumber(value) && !isError;

		if (isValidPhone !== prevPhoneNumberValid) {
			if (isValidPhone) {
				trackGtagEvent('sign_up_form_phone_number_validated');
			}
			else {
				trackGtagEvent('sign_up_form_phone_number_invalid');
			}
		}

		prevPhoneNumberValid = isValidPhone;
	};

	const checkPassword = (field) => {
		const { value } = field.target;
		const isValidPassword = isPasswordValid(value);

		if (isValidPassword !== prevPasswordValid) {
			if (isValidPassword) {
				trackGtagEvent('sign_up_form_password_validated');
			}
			else {
				trackGtagEvent('sign_up_form_password_invalid');
			}
		}
		prevPasswordValid = isValidPassword;
	};

	const onPhoneKeyPress = (e) => {
		const charStr = e.key.toString();
		if (!charStr.match(/^[0-9-]*$/)) {
			e.preventDefault()
		}
	};

	return ({
		addEventListeners: (needToCheckInputs = false) => {
			if (needToCheckInputs) {
				if (emailInput.value) {
					emailInput.addEventListener('blur', checkEmail);
				}
				if (passwordInput.value) {
					passwordInput.addEventListener('blur', checkPassword);
				}
				if (phoneInput.value) {
					phoneInput.addEventListener('blur', checkPhone);
					phoneInput.addEventListener('keypress', onPhoneKeyPress);
				}
			} else {
				emailInput.addEventListener('blur', checkEmail);
				passwordInput.addEventListener('blur', checkPassword);
				phoneInput.addEventListener('blur', checkPhone);
				phoneInput.addEventListener('keypress', onPhoneKeyPress);
			}
		},
		removeEventListeners: () => {
			emailInput.removeEventListener('blur', checkEmail);
			passwordInput.removeEventListener('blur', checkPassword);
			phoneInput.removeEventListener('blur', checkPhone);
			phoneInput.removeEventListener('keypress', onPhoneKeyPress);
		},
	});
}

const validateSecondStep = () => {
	const nameInput = signUpForm.querySelector("#signup-firstName");
	const surnameInput = signUpForm.querySelector("#signup-lastName");
	const couponCodeInput = signUpForm.querySelector("#signup-couponCode");
	const dayInput = signUpForm.querySelector("#signup-day");
	const dayErrorContainer = signUpForm.querySelector(".error-message.day-error");
	const monthInput = signUpForm.querySelector("#signup-month");
	const monthErrorContainer = signUpForm.querySelector(".error-message.month-error");
	const yearInput = signUpForm.querySelector("#signup-year");
	const yearErrorContainer = signUpForm.querySelector(".error-message.year-error");
	const dateErrorContainer = signUpForm.querySelector(".error__message.signup-dob");
	const selectHeadersArr = [...signUpForm.querySelectorAll('.signup__birthdate__row')];
	const selectListsArr = [...signUpForm.querySelectorAll('.select__list.js-select-list')];

	let prevNameValid = null;
	let prevSurnameValid = null;
	let prevDOBValid = null;
	let prevCouponCodeValid = null;

	secondStepInputsArr.forEach((input) => {
		const startInputTime = sessionStorage.getItem("startInputTime"); // In every session we will have new time

		if (!startInputTime) {
			sessionStorage.setItem("startInputTime", Date.now().toString());
		}

		input.addEventListener('input', (e) => validateSecondStepInput(e.target));
	});

	// need for revalidation if the user returned from the first step
	validateBirthday(
		dayInput.dataset.value,
		monthInput.dataset.value,
		yearInput.dataset.value,
		dayErrorContainer,
		monthErrorContainer,
		yearErrorContainer,
		dateErrorContainer,
	);

	[...selectHeadersArr, ...selectListsArr].forEach((item) => {
		item.addEventListener('click', () => {
			validateBirthday(
				dayInput.dataset.value,
				monthInput.dataset.value,
				yearInput.dataset.value,
				dayErrorContainer,
				monthErrorContainer,
				yearErrorContainer,
				dateErrorContainer,
			);
		})
	});

	const validateName = (e) => {
		const value = e.target.value.trim();
		const isValidName = !getTextFieldError(value);

		if (isValidName !== prevNameValid) {
			if (isValidName) {
				trackGtagEvent('sign_up_form_name_validated');
			} else {
				trackGtagEvent('sign_up_form_name_invalid');
			}
		}

		prevNameValid = isValidName;
		validateTextField(value, nameErrorContainer);
	};

	const validateSurname = (e) => {
		const value = e.target.value.trim();
		const isValidSurname = !getTextFieldError(value);

		if (isValidSurname !== prevSurnameValid) {
			if (isValidSurname) {
				trackGtagEvent('sign_up_form_surname_validated');
			} else {
				trackGtagEvent('sign_up_form_surname_invalid');
			}
		}

		prevSurnameValid = isValidSurname;
		validateTextField(value, surnameErrorContainer);
	};

	const validateDateOfBirth = () => {
		const day = dayInput.dataset.value;
		const month = monthInput.dataset.value;
		const year = yearInput.dataset.value;

		const hasErrors = validateBirthday(
			day,
			month,
			year,
			dayErrorContainer,
			monthErrorContainer,
			yearErrorContainer,
			dateErrorContainer,
		);
		const isValidDOB = !hasErrors;

		if (!day || !month || !year) return;

		if (isValidDOB !== prevDOBValid) {
			if (isValidDOB) {
				trackGtagEvent('sign_up_form_dob_validated');
			} else {
				trackGtagEvent('sign_up_form_dob_invalid');
			}
		}

		prevDOBValid = isValidDOB;
	};

	const validateCouponCode = (e) => {
		const value = e.target.value.trim();
		const errorMessage = getCouponError(value);
		const isValidCoupon = !errorMessage;

		if (isValidCoupon !== prevCouponCodeValid) {
			if (isValidCoupon) {
				trackGtagEvent('sign_up_form_coupon_validated');
			} else {
				trackGtagEvent('sign_up_form_coupon_invalid');
			}
		}

		prevCouponCodeValid = isValidCoupon;
	};

	return ({
		addEventListeners: () => {
			nameInput.addEventListener('blur', validateName);
			surnameInput.addEventListener('blur', validateSurname);
			[...selectHeadersArr, ...selectListsArr].forEach((el) => {
				el.setAttribute('tabindex', '-1');
				el.addEventListener('blur', validateDateOfBirth);
			});
			couponCodeInput.addEventListener('blur', validateCouponCode);
		},
		removeEventListeners: () => {
			nameInput.removeEventListener('blur', validateName);
			surnameInput.removeEventListener('blur', validateSurname);
			[...selectHeadersArr, ...selectListsArr].forEach((el) => {
				el.removeEventListener('blur', validateDateOfBirth);
			});
			couponCodeInput.addEventListener('blur', validateCouponCode);
		},
	});
}

export { addErrors, validateFirstStep, validateSecondStep, validateAllFirstStepInputs, validateAllSecondStepInputs };
